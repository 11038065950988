@use '@angular/material' as mat;

@use 'colors.scss';
@use 'palette.scss';

@include mat.elevation-classes();
@include mat.app-background();

$default-app-primary: mat.m2-define-palette(palette.$mat-primary-blue);
$default-app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$default-app-warn: mat.m2-define-palette(palette.$mat-secondary-red);

$secondary-app-primary: mat.m2-define-palette(palette.$mat-secondary-green);
$secondary-app-accent: mat.m2-define-palette(palette.$mat-secondary-yellow);
$secondary-app-warn: mat.m2-define-palette(palette.$mat-secondary-red);

$typography: mat.m2-define-typography-config(
  $font-family: 'Roboto, sans-serif',
  $button: mat.m2-define-typography-level(16px, 24px, 300),
);
@include mat.typography-hierarchy($typography);

$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $default-app-primary,
      accent: $default-app-accent,
      warn: $default-app-warn,
    ),
    typography: $typography,
    density: 0,
  )
);

@include mat.all-component-themes($theme);

// dark theme
.dark-theme {
  color: white;
  $dark-theme: mat.m2-define-dark-theme(
    (
      color: (
        primary: $default-app-primary,
        accent: $default-app-accent,
        warn: $default-app-warn,
      ),
    )
  );
  @include mat.all-component-colors($dark-theme);
}

// openreel secondary theme
.openreel-secondary-theme {
  $secondary-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $secondary-app-primary,
        accent: $secondary-app-accent,
        warn: $secondary-app-warn,
      ),
    )
  );
  @include mat.all-component-colors($secondary-theme);
}

.color-warn {
  color: mat.m2-get-color-from-palette($default-app-warn);
}

.white-bg-control .mdc-text-field--outlined {
  background-color: white !important;
}

.cdk-drag:not(.cdk-drag-disabled) {
  cursor: grab;
}

.cdk-drag-dragging {
  cursor: grabbing;
}
