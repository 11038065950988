/* You can add global styles to this file, and also import other style files */
@use './styles/mixins/text.mixin' as text;
@use 'colors' as colors;
@use 'libs/ui/styles/vjs.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;


$base-color: #bfc5d2;

$or-black: #27282b;

$or-white: white;

html,
body {
  @include text.text-basic();
  color: $or-black;
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
}

.content-wrapper {
  min-height: 100%;
  background-color: colors.$color-neutral-N100;

  .inner-content {
    padding-bottom: 90px;
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 415px) {
  .video-container {
    .hosting-player .vjs-poster,
    .video-js.hosting-player,
    .video-js.hosting-player:hover {
      box-shadow: none;
      border-radius: 0;
    }

    .video-js.hosting-player:hover video,
    .video-js.hosting-player video {
      box-shadow: none;
      border-radius: 0;
    }

    .video-js.hosting-player,
    .video-js.hosting-player:hover {
      box-shadow: none;
      border-radius: 0;

      .vjs-control-bar {
        box-shadow: none;
        border-radius: 0;
      }
    }
  }
}

.error-container {
  font-family: 'Roboto', sans-serif;
  margin-top: 20px;
  padding: 0 10px;

  .error-icon {
    width: 70px;
    height: 70px;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.missing {
      background-image: url('/assets/images/missing-logo.svg');
    }

    &.unauthorized {
      background-image: url('/assets/images/unauthorized-logo.svg');
    }
  }

  .error-header {
    font-weight: 700;
    font-size: 27px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: center;
  }

  .error-text {
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 1.2;
    text-align: center;
  }
}

.header-menu .menu-item .mdc-list-item__primary-text {
  line-height: inherit;
  font-size: inherit;
}

.mat-mdc-dialog-title {
  --mat-dialog-headline-padding: 24px 24px 9px;

  &::before {
    display: none !important;
  }
}

.mat-mdc-dialog-actions {
  --mat-dialog-actions-padding: 9px 24px;
}
